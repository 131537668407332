<template>
  <div>
    <v-client-table
      :columns="columns"
      :data="Mapdata"
      :options="options"
      theme="bootstrap4"
      id="dataTable"
    >
      <div slot="Movie" slot-scope="props">
        <span
          v-if="props.row.CategoryType == 'NowShowing'"
          style="color: #008000;"
        >{{ props.row.Movie }}</span>
        <span
          v-if="props.row.CategoryType == 'ComingSoon'"
          style="color: #ff0000;"
        >{{ props.row.Movie }}</span>
        <span v-if="props.row.CategoryType == ''">{{ props.row.Movie }}</span>
      </div>
    </v-client-table>
  </div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import MovieService from "@/services/MovieService";

export default {
  name: "DefaultAsideOne",
  components: {
    cSwitch
  },
  data() {
    return {
      Mapdata: "",
      columns: ["FilmName", "Format"],
      options: {
        headings: {
          FilmName: "Movie",
          Format: "Format",
          CategoryType: "CategoryType"
        },
        sortable: ["FilmName"],
        filterable: ["FilmName"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        }
      }
    };
  },
  beforeMount() {
    this.getProviderMovies();
  },
  methods: {
    getProviderMovies: function() {
      this.Mapdata = [];
      MovieService.getProviderMoviesList()
        .then(response => {
          const { data } = response;
          if (data.Status && data.ProviderMovieList.length > 0) {
            this.Mapdata = data.ProviderMovieList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    }
  }
};
</script>
