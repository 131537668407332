import { googleProjectId } from './constant';
import { languageCodes } from './collection';
import axios from 'axios';

export const translate = function (option, callback) {

    let language = languageCodes.filter((x) => { return x.name.toLowerCase() === option.language.toLowerCase() });
    if (language.length > 0) {
        // https://translation.googleapis.com/language/translate/v2?key=AIzaSyBGaZqIMLRnZgKmVEXs97OI2ev7o8t3iHE&q=hello&target=ar
        // axios.get(`https://translation.googleapis.com/language/translate/v2?key=AIzaSyBGaZqIMLRnZgKmVEXs97OI2ev7o8t3iHE&q=${option.text}&target=${option.language}`,
        axios.get(`https://translation.googleapis.com/language/translate/v2?key=AIzaSyBGaZqIMLRnZgKmVEXs97OI2ev7o8t3iHE&q=${option.text}&target=${language[0].code}`,
            {
                crossdomain: true
            })
            .then(function (response) {
                const { data } = response;
                if (data.hasOwnProperty('data') && data.data && data.data.hasOwnProperty('translations') && data.data.translations.length > 0) {
                    // console.log("check the translations array ", data)
                    callback({ status: true, message: 'success', data: data.data.translations[0] })
                } else {
                    callback({ status: false, message: 'Falied', data: null })
                }
            })
            .catch(function (error) {
                console.log("Error In Translation", error);
                callback({ status: false, message: error, data: null })
            });
    } else {
        callback({ status: false, message: "Language not found", data: null })

    }
}

// export const translate = function (option, callback) {
//     axios.get('https://statickidz.com/scripts/traductor/', {
//         params: {
//             source: "en",
//             target: 'ar',
//             q: "Hello World"
//         }
//     })
//         .then(function (response) {
//             console.log("KKKKKKKKKKKKKK ", response)
//             callback(response);
//         })
//         .catch(function (error) {
//             console.log("KKKKKKKKKKKKKK ", response)

//             console.log(error);
//         });
// }
