<template>
  <div class="animated fadeIn">
    New Movie
    <div id="snackbar"></div>

    <!-- <AsideToggler variant="outline-primary" class="d-none d-lg-block CustomAside" /> -->

    <b-card class="p-2">
      <h4 class="text-center">Do you want to auto populate the Movies Details</h4>
      <br />
      <b-row>
        <b-col sm="6" offset="3">
          <b-input-group>
            <!-- Attach Left button -->
            <b-form-input type="text" v-model="searchMoviekey" placeholder="Search"></b-form-input>
            <b-input-group-prepend>
              <b-button variant="primary" @click="searchMovies()">
                <i class="fa fa-search"></i> Search
              </b-button>
            </b-input-group-prepend>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="6" offset="3" class="mt-3">
          <b-form-select
            id="basicSelectLg"
            v-model="selectedSearchMovie"
            @change.native="selectSearchesMovie($event.target.value)"
            size="md"
            :plain="true"
            value-field="Title"
            text-field="Title"
            :options="searchMovieList"
          >
            <!-- value="Please select" -->
            <template slot="first">
              <option :value="null" disabled>-- Select Movie --</option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>

    <b-row>
      <b-col sm="9" class="mt-3">
        <b-card>
          <b-tabs card v-if="contentLanguageList.length > 0" v-model="tabIndex">
            <b-tab
              v-for="(i, index) in contentLanguageCount"
              :key="`dyn-tab-${i}`"
              :title="`${contentLanguageList[index].hasOwnProperty('name') ? contentLanguageList[index].name : '' }`"
            >
              ContentID: {{ contentLanguageList[index].ContentID }}
              <br />
              <br />
              <!-- <b-form v-on:submit.prevent.self="submit"> -->
              <b-form>
                <b-row>
                  <b-col sm="3">
                    <b-form-group>
                      <label for="title">Movie Title</label>
                      <b-form-input
                        type="text"
                        required
                        v-model="movieContainer[index].Title"
                        id="title"
                        placeholder="Movie Title"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="3">
                    <b-form-group>
                      <label for="genre">Genre</label>
                      <b-form-input
                        type="text"
                        required
                        v-model="movieContainer[index].Genre"
                        id="genre"
                        placeholder="Genre"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="3">
                    <b-form-group>
                      <label for="directors">Directors</label>
                      <b-form-input
                        type="text"
                        required
                        v-model="movieContainer[index].Director"
                        id="directors"
                        placeholder="Directors"
                        @change="getDirectorCount(index)"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group>
                      <label for="actors">Actors</label>
                      <b-form-input
                        type="text"
                        required
                        v-model="movieContainer[index].Actors"
                        id="actors"
                        placeholder="Actors"
                        @change="getActorCount(index)"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="3">
                    <b-form-group>
                      <label for="plot">Plot</label>
                      <b-form-input
                        type="text"
                        required
                        v-model="movieContainer[index].Plot"
                        id="plot"
                        placeholder="Plot"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group>
                      <label for="language">Language</label>
                      <b-form-input
                        type="text"
                        required
                        @change.native="onChangeLanguage(index)"
                        v-model="movieContainer[index].Language"
                        id="language"
                        placeholder="Language"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group>
                      <label for="imdbrating">Imdb Rating</label>
                      <b-form-input
                        type="text"
                        v-model="movieContainer[index].imdbRating"
                        id="imdbrating"
                        placeholder="Imdb Rating"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-card class="p-2" no-body v-if="movieContainer[index].ActorDetail.length >0">
                  <h4>UPLOAD ACTOR IMAGES</h4>
                  <b-row
                    v-for="(actor, actorIndex) in movieContainer[index].ActorDetail"
                    :key="actorIndex+'actor'"
                    class="mt-2"
                  >
                    <b-col sm="6">
                      Cast Images
                      <br />
                      Size: {{ actorImagesSize }}
                      <b-form-group class="mt-1">
                        {{ actor.Name }} :
                        <b-form-file
                          ref="fileinputActor"
                          id="fileInput"
                          v-on:change="uploadImage($event.target,index ,actorIndex, actor.Name, 'actor')"
                          :plain="true"
                        ></b-form-file>
                      </b-form-group>
                    </b-col>
                    <b-col sm="6" :id="'previewCast-'+actor.Name.replace(/\s/g, '').trim()">
                      <img
                        v-if="actor.Image != undefined && actor.Image != ''"
                        :src="BaseURL+actor.Image"
                        class="img-fluid"
                      />
                    </b-col>
                  </b-row>
                </b-card>

                <b-card class="p-2" no-body v-if="movieContainer[index].DirectorDetail.length > 0">
                  <h4>UPLOAD DIRECTOR IMAGES</h4>

                  <b-row
                    v-for="(director, directorIndex) in movieContainer[index].DirectorDetail"
                    :key="directorIndex+'director'"
                    class="mt-2"
                  >
                    <b-col sm="6">
                      Director Images
                      <br />
                      Size: {{ directorImagesSize }}
                      <b-form-group class="mt-1">
                        {{ director.Name }} :
                        <b-form-file
                          ref="fileinputDirector"
                          id="fileInput"
                          v-on:change="uploadImage($event.target, index,directorIndex, director.Name, 'director')"
                          :plain="true"
                        ></b-form-file>
                      </b-form-group>
                    </b-col>
                    <b-col sm="6" :id="'previewDir-'+director.Name.replace(/\s/g, '').trim()">
                      <img
                        v-if="director.Image != undefined && director.Image != ''"
                        :src="BaseURL+director.Image"
                        class="img-fluid"
                      />
                    </b-col>
                  </b-row>
                </b-card>

                <b-card
                  class="p-2"
                  no-body
                  v-if="movieContainer[index].MoviesFormatList.length > 0"
                >
                  <b-row>
                    <b-col sm="8">
                      <div
                        v-for="(item, _index) in movieContainer[index].MoviesFormatList"
                        :key="_index"
                      >
                        <div
                          style="display:flex;align-items: center;justify-content: space-between;"
                        >
                          <div>
                            <b-form-group>
                              <label v-if="_index == 0" for="YoutubeLink">Youtube Link</label>
                              <b-form-input
                                type="text"
                                required
                                v-model="item.youtube"
                                id="YoutubeLink"
                                placeholder="Youtube Link"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div>
                            <!-- :disabled="MovieID ? true :false" -->
                            <b-form-group>
                              <label v-if="_index == 0" for="Format">Format</label>
                              <b-form-input
                                type="text"
                                required
                                v-model="item.value"
                                id="language"
                                placeholder="Format"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div
                            class="SetMargin"
                            :style="{  'margin-top': _index == 0 ? '14px;' : '-14px'}"
                          >
                            <!-- :disabled="MovieID ? true :false" -->
                            <b-form-select
                              required
                              v-model="item.language"
                              :id="'cinemas'+contentLanguageList[index].name+index+_index"
                              :plain="true"
                              :options="movieContainer[index]['moviesLanguageList']"
                            ></b-form-select>
                          </div>
                          <div>
                            <i
                              v-if="_index === 0"
                              style="margin-top: 1rem;"
                              class="fa fa-plus-square-o text-success mt-3 mx-2"
                              @click="addFormat(index, _index)"
                            ></i>
                            <i
                              v-if="_index !== 0"
                              style="margin-top: -15px;"
                              class="fa fa-minus-square-o text-danger mx-2"
                              @click="removeFormat(index,_index)"
                            ></i>
                          </div>
                        </div>

                        <b-row class="mt-2">
                          <b-col sm="6">
                            <b-form-group class="mt-1">
                              <label for="logo">
                                Main Banner
                                <br />
                                Size: {{ MainBannerSize }}
                              </label>
                              <b-form-file
                                ref="fileinputMainBanner"
                                :id="'fileInputBanner-'+_index"
                                v-on:change="uploadBanner($event.target,index, _index, 'MainBanner', MainBannerSize,'assets/images/movies/MainBanner')"
                                accept="image/jpeg, image/png, image/gif"
                                required
                                :plain="true"
                              ></b-form-file>
                              <input type="hidden" />
                            </b-form-group>
                          </b-col>
                          <b-col sm="6" :id="'previewBanner-MainBanner-'+_index">
                            <img
                              v-if="item['MainBanner'] != undefined && item['MainBanner'] != ''"
                              :src="BaseURL+item['MainBanner']"
                              class="img-fluid"
                            />
                          </b-col>

                          <!-- Main Mobile Banner -->

                          <b-col sm="6">
                            <b-form-group class="mt-1">
                              <label for="logo">
                                Main Mobile Banner
                                <br />
                                Size: {{ MainMobileBannerSize }}
                              </label>
                              <b-form-file
                                ref="fileinputMainMobileBanner"
                                :id="'fileInputBanner-'+_index"
                                v-on:change="uploadBanner($event.target,index, _index, 'MainMobileBanner', MainMobileBannerSize,'assets/images/movies/MainMobileBanner')"
                                accept="image/jpeg, image/png, image/gif"
                                required
                                :plain="true"
                              ></b-form-file>
                              <input type="hidden" />
                            </b-form-group>
                          </b-col>
                          <b-col sm="6" :id="'previewBanner-MainMobileBanner-'+_index">
                            <img
                              v-if="item['MainMobileBanner'] != undefined && item['MainMobileBanner'] != ''"
                              :src="BaseURL+item['MainMobileBanner']"
                              class="img-fluid"
                            />
                          </b-col>
                          <!-- Main Mobile Banner End -->

                          <!-- Main Large Banner Start -->

                          <b-col sm="6">
                            <b-form-group class="mt-1">
                              <label for="logo">
                                Main Large Banner
                                <br />
                                Size: {{ MainLargeBannerSize }}
                              </label>
                              <b-form-file
                                ref="fileinputMainLargeBanner"
                                :id="'fileInputBanner-'+_index"
                                v-on:change="uploadBanner($event.target,index, _index, 'MainLargeBanner', MainLargeBannerSize,'assets/images/movies/MainLargeBanner')"
                                accept="image/jpeg, image/png, image/gif"
                                required
                                :plain="true"
                              ></b-form-file>
                              <input type="hidden" />
                            </b-form-group>
                          </b-col>
                          <b-col sm="6" :id="'previewBanner-MainLargeBanner-'+_index">
                            <img
                              v-if="item['MainLargeBanner'] != undefined && item['MainLargeBanner'] != ''"
                              :src="BaseURL+item['MainLargeBanner']"
                              class="img-fluid"
                            />
                          </b-col>
                          <!-- Main Large Banner End -->

                          <b-col sm="6">
                            <b-form-group class="mt-1">
                              <label for="logo">
                                Movie Poster
                                <br />
                                Size: {{ MoviePosterSize }}
                              </label>
                              <b-form-file
                                ref="fileinputAppHeaderBanner"
                                :id="'fileInputBanner-'+index"
                                v-on:change="uploadBanner($event.target, index,_index, 'AppHeaderBanner', MoviePosterSize,'assets/images/movies/HeaderImage')"
                                accept="image/jpeg, image/png, image/gif"
                                required
                                :plain="true"
                              ></b-form-file>
                              <!-- ="movie.MovieID == null ? true : false" -->
                              <input type="hidden" />
                            </b-form-group>
                          </b-col>
                          <b-col sm="6" :id="'previewBanner-AppHeaderBanner-'+_index">
                            <img
                              v-if="item['AppHeaderBanner'] != undefined && item['AppHeaderBanner'] != ''"
                              :src="BaseURL+item['AppHeaderBanner']"
                              class="img-fluid"
                            />
                          </b-col>
                          <!-- <b-col sm="6">
                            <b-form-group class="mt-1">
                              <label for="logo">
                                App Image
                                <br>
                                Size: {{ AppImageSize }}
                              </label>
                              <b-form-file
                                ref="fileinputAppImage"
                                :id="'fileInputBanner-'+_index"
                                v-on:change="uploadBanner($event.target, index,_index, 'AppImage', AppImageSize,'assets/images/movies/AppImage')"
                                accept="image/jpeg, image/png, image/gif"
                                required
                                :plain="true"
                              ></b-form-file>
                              <input type="hidden">
                            </b-form-group>
                          </b-col>-->
                          <b-col sm="6" :id="'previewBanner-AppImage-'+_index">
                            <img
                              v-if="item['AppImage'] != undefined && item['AppImage'] != ''"
                              :src="BaseURL+item['AppImage']"
                              class="img-fluid"
                            />
                          </b-col>
                        </b-row>
                        <h4>--------------------------------------------------------------------</h4>
                      </div>
                    </b-col>
                  </b-row>
                </b-card>

                <!-- Country Data -->

                <b-card
                  v-for="(item, MovieCountryIndex) in MovieCountryData"
                  :key="MovieCountryIndex"
                  no-body
                  class="mb-1"
                >
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button
                      block
                      :class="item.showCollapse ? 'collapsed' : null"
                      :aria-expanded="item.showCollapse ? 'true' : 'false'"
                      :aria-controls="item.CollapsedID"
                      @click="item.showCollapse = !item.showCollapse"
                    >{{ item.CountryName}}</b-button>
                  </b-card-header>
                  <b-collapse
                    :id="item.CollapsedID"
                    v-model="item.showCollapse"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-row>
                        <b-col sm="3">
                          <b-form-group>
                            <label for="rated">Rated</label>
                            <b-form-input
                              type="text"
                              required
                              v-model="item.Rated"
                              id="rated"
                              placeholder="Rated"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col sm="3">
                          <b-form-group>
                            <label for="released">Movie Released</label>
                            <b-form-input
                              type="text"
                              required
                              v-model="item.Released"
                              id="released"
                              placeholder="Movie Released"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col sm="3">
                          <b-form-group>
                            <label for="runtime">Runtime (Please add 'mins')</label>
                            <b-form-input
                              type="text"
                              required
                              v-model="item.Runtime"
                              id="runtime"
                              placeholder="Runtime"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col sm="3">
                          <b-form-group>
                            <label for="DistributorName">Distributor Name</label>
                            <b-form-input
                              type="text"
                              required
                              v-model="item.DistributorName"
                              id="DistributorName"
                              placeholder="Distributor Name"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <!-- Country Data End -->
              </b-form>
            </b-tab>

            <!-- Render this if no tabs -->
            <!-- <div slot="empty" class="text-center text-muted">
          There are no open tabs
          <br>Open a new tab using the
          <b>+</b> button above.
            </div>-->
            <!-- Next and Previous button for changing tab -->
            <!-- <div v-if="contentLanguageList.length > 0" class="text-center">
          <b-button-group class="mt-2">
            <b-button @click="tabIndex--">Previous</b-button>&nbsp;&nbsp;
            <b-button @click="tabIndex++">Next</b-button>
          </b-button-group>
          <div class="text-muted">Current Tab: {{ contentLanguageList[tabIndex].name }}</div>
            </div>-->
            <div v-if="contentLanguageList.length > 0" class="text-center d-none">
              <b-button-group class="mt-2">
                <b-button @click="translateMovies">PROCEED</b-button>
              </b-button-group>
            </div>
          </b-tabs>
          <br />
          <br />
          <br />

          <b-row>
            <b-col sm="6">
              <b-button @click="submit" type="submit" size="sm" variant="primary">
                <i class="fa fa-dot-circle-o"></i>
                Final Submit
              </b-button>
            </b-col>

            <b-col sm="6">
              <b-button @click="resetForm" size="sm" variant="success">
                <i class="fa fa-dot-circle-o"></i> Reset
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!-- Map Movies Table Here -->
      <b-col sm="3" class="mt-3">
        <v-client-table
          :columns="columns"
          :data="Mapdata"
          :options="options"
          theme="bootstrap4"
          id="dataTable"
        >
          <div slot="Movie" slot-scope="props">
            <span
              v-if="props.row.CategoryType == 'NowShowing'"
              style="color: #008000;"
            >{{ props.row.Movie }}</span>
            <span
              v-if="props.row.CategoryType == 'ComingSoon'"
              style="color: #ff0000;"
            >{{ props.row.Movie }}</span>
            <span v-if="props.row.CategoryType == ''">{{ props.row.Movie }}</span>
          </div>
        </v-client-table>
      </b-col>
    </b-row>

    <b-modal
      id="ModalConfirmationForConvert"
      ref="ModalConfirmationForConvert"
      @ok="convertLanguageConfirm"
      class="center-footer"
      footer-class="justify-content-center border-0"
      cancel-variant="outline-danger"
      ok-variant="outline-success"
      centered
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="d-block text-center">
        <div>{{ message.convertMessage }}</div>
      </div>
    </b-modal>

    <b-modal
      id="ModalConfirmationOnSubmit"
      ref="ModalConfirmationOnSubmit"
      @ok="handleModalOkay"
      :title="modalObject.title"
      class="center-footer"
      footer-class="justify-content-center border-0"
      cancel-variant="outline-danger"
      ok-variant="outline-success"
      centered
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="d-block text-center">
        <div>{{ modalObject.message }}</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import MovieService from "@/services/MovieService";
import MasterService from "@/services/MasterService";

import CinemaService from "@/services/CinemaService";
import UploadService from "@/services/UploadService";
import CommonService from "@/services/CommonService";
import { Switch as cSwitch } from "@coreui/vue";
import { translate } from "@/helper/translation.js";
import DefaultAsideOne from "./MovieAside/DefaultAsideOne";
import { AsideToggler, Aside as AppAside } from "@coreui/vue";

const Jquery = require("jquery");

import { SidebarToggler } from "@coreui/vue";

const fixedObject = {
  Title: "",
  // Year: "",
  // Rated: "", //-
  // Released: "", //-
  // Runtime: "", //-
  Genre: "", // : sapreated
  Director: "",
  // Writer: "",
  Actors: "",
  Plot: "",
  Language: "",
  // Country: "",
  // Awards: "",
  // Poster: "",
  // Metascore: "",
  imdbRating: ""
  // imdbVotes: "",
  // imdbID: "",
  // Type: "",
  // DVD: "",
  // BoxOffice: "",
  // Production: "",
  // Website: "",
  // Response: "",
  // Rotten_Tomatoes: ""
};
const MoviesFormatList = [
  {
    value: "",
    language: "Select Language",
    IsDisabled: false,
    youtube: "",
    ID: 0,
    MainBanner: "",
    MainMobileBanner: "",
    MainLargeBanner: "",
    AppHeaderBanner: "",
    AppImage: ""
  }
];

export default {
  name: "AddMovies",
  components: {
    cSwitch,
    SidebarToggler,
    AsideToggler,
    DefaultAsideOne,
    AppAside
  },
  data() {
    return {
      MovieID: null,
      showCollapse: true,
      message: {
        convertMessage: ""
      },
      searchMoviekey: "",
      searchMovieList: [],
      selectedSearchMovie: null,
      tabIndex: 0,
      actorImagesSize: "100*100",
      directorImagesSize: "100*100",
      // Ticketak
      MoviePosterSize: "325*488",
      AppImageSize: "666*999",
      MainBannerSize: "1920*575", //"1280*720",
      MainMobileBannerSize: "575*275", // "1280*720",
      MainLargeBannerSize: "1920*575", // "1280*720",

      BaseURL: "",
      contentLanguageList: [],
      contentLanguageCount: 10,
      movieContainer: [],
      moviesLanguageList: ["Select Language"],
      //
      Mapdata: [],
      columns: ["FilmName", "Format"],
      options: {
        headings: {
          FilmName: "Movie",
          Format: "Format",
          CategoryType: "CategoryType"
        },
        sortable: ["FilmName"],
        filterable: ["FilmName"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        }
      },
      //
      ThirdpartyMovieDetails: {
        status: false,
        Title: "",
        Metacritic: "",
        Genre: "",
        Language: "",
        Runtime: "",
        Plot: ""
      },
      MoviesObject: {
        ContentID: "",
        ContentLanguageName: "",
        // MovieMasterID: "",
        // ThirdpartyID: "",
        ...fixedObject,
        DirectorDetail: [],
        ActorDetail: [],
        moviesLanguageList: [],

        MovieType: "Planned",
        MovieIsActive: true
      },
      MovieCountryData: []
    };
  },
  beforeMount() {
    this.getContentLanguageList();
    this.getProviderMovies();
    const { MovieID } = this.$route.query;
    this.MovieID = this.$route.query.MovieID;
    if (MovieID) {
      this.getMovieDetailsById(MovieID);
      this.MovieCountryData = [];
    } else {
      this.getActiveCountryList();
    }
  },
  mounted() {
    // const { thirdpartyID, movieName } = this.$route.query;
    // if (thirdpartyID && movieName) {
    //   this.getMovieByThirdpartyID(thirdpartyID);
    // }
  },
  methods: {
    getMovieDetailsById: function(MovieID) {
      let payload = { MovieID };
      // const { data } = await MovieService.getMovieByID(payload);
      MovieService.getMovieByID(payload)
        .then(response => {
          const { data } = response;
          if (data && data.Status && data.MovieContainer.length > 0) {
            this.MovieCountryData = data.MovieCountryData.map((x, index) => {
              x["showCollapse"] = true;
              x["CollapsedID"] = "accordion-" + index;
              return x;
            });
            // showCollapse: false,
            // CollapsedID: "accordion-" + (index + 1)

            this.movieContainer = data.MovieContainer;

            this.BaseURL = data.BaseURL;
            // this.moviesObject.MovieUpdateId = MovieID;
            // this.status.moviesContent = true;
            // if (data.MovieList[0].MoviesFormatList.length > 0) {
            //   this.moviesObject.moviesFormatList =
            //     data.MovieList[0].MoviesFormatList;
            // } else {
            //   this.moviesObject.moviesFormatList = [
            //     {
            //       value: "",
            //       language: "Select Language",
            //       IsDisabled: false,
            //       youtube: "",
            //       ID: 0,
            //       MainBanner: "",
            //       AppHeaderBanner: "",
            //       AppImage: ""
            //     }
            //   ];
            // }
            // this.movie = data.MovieList[0];

            // this.$nextTick(() => {
            //   this.setMovieDetails(data.MovieList[0]);
            // });
          } else {
            this.showMessageSnackbar(data.Message || "Request Status False");
          }
        })
        .then(() => {
          this.movieContainer.map(async (x, index) => {
            await this.onChangeLanguage(index);
          });
        })
        .catch(error => {
          console.log("getMovieByID Error -------", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    setMovieDetails: function(movieObj) {
      let self = this;
      // this.movie = movieObj;
      let links = [];

      this.autoInput = 0;
      this.movie.YoutubeLinks = movieObj.YoutubeLinks;
      movieObj.YoutubeLinks.map(yt => {
        this.autoInput++;
      });

      this.movie.Metacritic = movieObj.Metacritic.split(",");
      this.movie.Metacritic.map(yt => {
        this.metacriticList++;
      });
      this.onChangeLanguage();

      this.moviesObject.filter.selectedContentLanguage =
        movieObj["ContentLanguageID"];
      this.moviesObject.filter.selectedMovie = movieObj["MovieMasterID"];

      self.actorImages = [];
      self.directorImages = [];

      setTimeout(() => {
        if (movieObj.ActorDetail.length > 0) {
          movieObj.ActorDetail.map(x => {
            self.actorImages.push({
              Image: x.Image,
              Name: x.Name
            });
          });
        }

        if (movieObj.DirectorDetail.length > 0) {
          movieObj.DirectorDetail.map(x => {
            // self.directorImages.push(dimg.Image);
            self.directorImages.push({ Image: x.Image, Name: x.Name });
          });
        }
      }, 5000);
    },
    getActiveCountryList(id) {
      this.MovieCountryData = [];
      MasterService.getActiveCountries()
        .then(response => {
          const { data } = response;
          if (data.Status && data.CountryList.length > 0) {
            data.CountryList.map((x, index) => {
              this.MovieCountryData.push({
                Rated: "",
                Released: "",
                Runtime: "",
                CountryID: x.CountryID,
                DistributorName: "",
                CountryName: x.CountryName,
                showCollapse: false,
                CollapsedID: "accordion-" + (index + 1)
              });
            });
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    onCollapseHandler: function(event, item, index) {
      console.log("index :", index);
      // this.MovieCountryData.map((x, _index) => {
      //   console.log("index == _index :", index == _index);
      //   // if (index == _index) {
      //   //   x.showCollapse = !x.showCollapse;
      //   // } else {
      //   //   x.showCollapse = false;
      //   // }
      // });
    },
    resetForm: function() {
      console.log("Reset Form");
      this.contentLanguageCount = 10;
      this.movieContainer = [];
      this.contentLanguageList = [];
      this.getContentLanguageList();
      this.tabIndex = 0;
      this.getActiveCountryList();
      this.$forceUpdate();
    },
    getContentLanguageList: function() {
      this.movieContainer = [];
      this.contentLanguageList = [];
      MovieService.getContentLanguageList()
        .then(response => {
          const { data } = response;
          if (data && data.Status && data.ContentLanguageList.length > 0) {
            this.contentLanguageList = data.ContentLanguageList;
            this.contentLanguageCount = data.ContentLanguageList.length;

            data.ContentLanguageList.map(x => {
              console.log("x", x);
              this.movieContainer.push({
                ...this.MoviesObject,
                MoviesFormatList: MoviesFormatList,
                ContentLanguageName: x.name,
                ContentID: x.ContentID
              });
            });
            console.log("this.movieContainer :", this.movieContainer);
          } else {
            this.showMessageSnackbar("Something went wrong message");
          }
        })
        .catch(error => {
          console.log("Catch getContentLanguageList Error", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getProviderMovies: function() {
      this.Mapdata = [];
      MovieService.getProviderMoviesList()
        .then(response => {
          const { data } = response;
          if (data.Status && data.ProviderMovieList.length > 0) {
            this.Mapdata = data.ProviderMovieList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getMovieByThirdpartyID: async function(ThirdpartyID) {
      let payload = { ThirdpartyID };
      MovieService.getMovieByThirdpartyID(payload)
        .then(response => {
          const { data } = response;
          this.ThirdpartyMovieDetails.thirdpartyId = ThirdpartyID;
          if (Object.keys(data).length > 0) {
            console.log("Showbizz Response -", data);
            this.getThirdpartyMovie(data);
            this.movieContainer.map(x => {
              x.ThirdpartyID = ThirdpartyID;
              x.Title = data.FilmName;
              x.moviesFormatList[0].value = data.Format;
              x.Genre = data.Genre;
              x.moviesFormatList[0].language = data.Language;
              x.Runtime = data.RunningTime;
              x.Plot = data.Synopsis;
            });
          }
        })
        .catch(error => {
          console.log("Catch getMovieByThirdpartyID Error", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getThirdpartyMovie: function(data) {
      this.ThirdpartyMovieDetails.status = true;
      this.ThirdpartyMovieDetails.Title = data.FilmName;
      this.ThirdpartyMovieDetails.Metacritic = data.Format;
      this.ThirdpartyMovieDetails.Genre = data.Genre;
      console.log("data.Genre :", data.Genre);

      this.ThirdpartyMovieDetails.Language = data.Language;
      this.ThirdpartyMovieDetails.Runtime = data.RunningTime;
      this.ThirdpartyMovieDetails.Plot = data.Synopsis;
    },

    searchMovies: function() {
      let self = this;
      Jquery.ajax({
        url: `http://omdbapi.com/?s=${self.searchMoviekey}&apikey=46375f6b`,
        method: "GET"
      })
        .then(function(res) {
          if (res && res.hasOwnProperty("Search") && res.Search.length > 0) {
            self.searchMovieList = res.Search;
            self.showMessageSnackbar(
              `${res.Search.length} result found related to the Searches`
            );
          }
        })
        .catch(function(error) {
          console.log("Error Add movie Page", error);
          self.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    selectSearchesMovie: function(e) {
      let self = this;
      Jquery.ajax({
        url: `http://omdbapi.com/?t=${e}&apikey=46375f6b`, // `http://omdbapi.com/?s=${this.search}&apikey=46375f6b`,
        method: "GET"
      })
        .then(async function(res) {
          if (res.Response !== "False") {
            console.log("res :", res);
            // if(self.contentLanguageList[0].name.toLowerCase() !== 'english'){
            // self.message.convertMessage = `Do you really want to convert movie details into ${self.contentLanguageList[0].name}`
            // } else{
            // }
            // let fixedArray = ["Director", "Language", "Actors"];

            await self.movieContainer.map(async (x, index) => {
              if (index == 0) {
                // console.log("x.length, typeof x :", x.length, typeof x);
                await Object.keys(fixedObject).map(y => {
                  if (y !== "Director" && y !== "Genre" && y !== "Actors")
                    x[y] = res[y];
                  else if (
                    y === "Director" ||
                    // y === "Language" ||
                    y === "Actors" ||
                    y === "Genre"
                  )
                    x[y] = res[y].replace(/,/g, ":");
                });
                await res.Ratings.map(r => {
                  if (r.Source === "Rotten Tomatoes") {
                    x.Rotten_Tomatoes = r.Value;
                  } else if (r.Source === "Internet Movie Database") {
                    x.imdbRating = r.Value;
                  }
                });
                await self.getDirectorCount(index);
                await self.getActorCount(index);
                await self.onChangeLanguage(index);
                return x;
              }
            });
            await self.MovieCountryData.map((x, index) => {
              x.Rated = res.Rated;
              x.Released = res.Released;
              x.Runtime = res.Runtime;
            });
            self.$forceUpdate();
            // self.getThirdpartyMovieDetails();
          } else {
            self.showMessageSnackbar(res.Error);
          }
        })
        .catch(function(error) {
          console.log("Catch on Select Auto movie", error);
          self.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    convertLanguageConfirm: function() {},

    translateMovies: function() {
      let self = this;
      let array = [
        "Title",
        "Genre",
        "Director",
        "Actors",
        "Plot",
        "Language",
        "MoviesFormatList",
        //
        "ActorDetail",
        "DirectorDetail"
      ];

      let copyArray = ["imdbRating"];

      // const temp = null; // self.movieContainer[0][y]; // x["ActorDetail"]

      let promise = new Promise(function(resolve, reject) {
        setTimeout(() => resolve("done!"), 1000);
      });

      function titleCase(str) {
        var splitStr = str.toLowerCase().split(" ");
        for (var i = 0; i < splitStr.length; i++) {
          // You do not need to check if i is larger than splitStr length, as your for does that for you
          // Assign it back to the array
          splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(" ");
      }

      self.movieContainer = self.movieContainer.map((x, index) => {
        // self.onChangeLanguage(index);
        // DON'T CONVERT FOR ENGLISH
        console.log("x.ContentLanguageName :", x.ContentLanguageName);
        x.Title = x.Title ? titleCase(x.Title) : x.Title;
        console.log(
          'x.ContentLanguageName.toLowerCase() !== "english" :',
          x.ContentLanguageName.toLowerCase() !== "english"
        );
        if (x.ContentLanguageName.toLowerCase() !== "english") {
          console.log("x :", x, index);
          copyArray.map(y => {
            x[y] = self.movieContainer[0][y];
          });

          array.map(async y => {
            // CLONE ALL FIELDS DATA FROM ZERO (ENGLISH)
            x[y] = self.movieContainer[0][y];

            if (
              index !== 0 &&
              x.ContentLanguageName.toLowerCase() !== "english" &&
              y === "MoviesFormatList"
            ) {
              // let promise =
              const temp = x.MoviesFormatList; // self.movieContainer[0][y];
              let empty = [];
              temp.map(z => {
                translate(
                  {
                    text: z.language, // self.movieContainer[0][y],
                    language: x.ContentLanguageName
                  },
                  result => {
                    if (result.status) {
                      // z.language = result.data.translatedText;
                      empty.push({
                        ...z,
                        language: result.data.translatedText
                      });
                    } else {
                      self.showMessageSnackbar(result.message);
                    }
                  }
                );
                // return z;
                x.MoviesFormatList = empty;
              });
              // Promise.all(promise)
            } else if (y !== "ActorDetail" && y !== "DirectorDetail") {
              console.log("y :", y);
              translate(
                {
                  text: x[y], // self.movieContainer[0][y],
                  language: x.ContentLanguageName
                },
                result => {
                  if (result.status) {
                    x[y] = result.data.translatedText;
                  } else {
                    self.showMessageSnackbar(result.message);
                  }
                }
              );
            }
          });
        }
        return x;
      });
      // console.log("self.movieContainer :", self.movieContainer);
      setTimeout(() => {
        self.movieContainer.map((x, index) => {
          self.onChangeLanguage(index);
          if (
            index !== 0 &&
            x.ContentLanguageName.toLowerCase() !== "english" &&
            x.hasOwnProperty("ActorDetail")
          ) {
            const temp = x.ActorDetail; // self.movieContainer[0][y];
            let empty = [];
            temp.map((c, _index) => {
              translate(
                {
                  text: c.Name, // self.movieContainer[0][y],
                  language: x.ContentLanguageName
                },
                result => {
                  if (result.status) {
                    empty.push({ ...c, Name: result.data.translatedText });
                  } else {
                    self.showMessageSnackbar(result.message);
                  }
                }
              );
            });
            // console.log("temp :", temp, empty);
            x.ActorDetail = empty;
          }
          if (
            index !== 0 &&
            x.ContentLanguageName.toLowerCase() !== "english" &&
            x.hasOwnProperty("DirectorDetail")
          ) {
            const temp = x.DirectorDetail; // self.movieContainer[0][y];
            let empty = [];
            temp.map((c, _index) => {
              translate(
                {
                  text: c.Name, // self.movieContainer[0][y],
                  language: x.ContentLanguageName
                },
                result => {
                  if (result.status) {
                    empty.push({ ...c, Name: result.data.translatedText });
                  } else {
                    self.showMessageSnackbar(result.message);
                  }
                }
              );
            });
            x.DirectorDetail = empty;
          }
        });
        self.tabIndex++;
        self.$forceUpdate();
      }, 2000);
    },

    getActorCount: async function(index) {
      if (this.movieContainer[index].Actors !== "N/A") {
        let array = this.movieContainer[index].Actors.split(":");
        let tempArray = [];
        await array.map(async (x, _index) => {
          if (_index < 3) {
            await tempArray.push({
              Image: "",
              Name: x
            });
          }
        });
        this.movieContainer[index].ActorDetail = tempArray;
      }
    },
    getDirectorCount: async function(index) {
      if (this.movieContainer[index].Director !== "N/A") {
        let array = this.movieContainer[index].Director.split(":");
        let tempArray = [];
        await array.map(async (x, _index) => {
          if (_index == 0) {
            await tempArray.push({
              Image: "",
              Name: x
            });
          }
        });
        this.movieContainer[index].DirectorDetail = tempArray;
      }
    },
    uploadImage: function(image, mainIndex, currentIndex, crewName, type) {
      var self = this;
      var _URL = window.URL || window.webkitURL;
      var file, img;
      if ((file = image.files[0])) {
        img = new Image();
        img.src = _URL.createObjectURL(file);
        img.onload = async function() {
          // if (false) {
          if (img.height != 100 || img.width != 100) {
            self.showMessageSnackbar("Please upload a 100 x 100 image");
            if (type == "actor") {
              self.$refs.fileinputActor.map((f, FIndex) => {
                if (FIndex == currentIndex) {
                  f.reset();
                }
              });
            } else {
              self.$refs.fileinputDirector.map((f, FIndex) => {
                if (FIndex == currentIndex) {
                  f.reset();
                }
              });
            }
          } else {
            var formdata = new FormData();
            if (type == "actor") {
              formdata.append("assets/images/movies/cast", image.files[0]);
            } else {
              formdata.append("assets/images/movies/directors", image.files[0]);
            }
            formdata.append("rename", crewName);
            UploadService.uploadFiles(formdata)
              .then(response => {
                const { data } = response;
                data.Message ? self.showMessageSnackbar(data.Message) : "";
                if (data.Status) {
                  if (type == "actor") {
                    self.movieContainer[mainIndex].ActorDetail[
                      currentIndex
                    ].Image = data.Path;
                  } else {
                    self.movieContainer[mainIndex].DirectorDetail[
                      currentIndex
                    ].Image = data.Path;
                  }
                  self.BaseURL = data.BaseURL;
                }
              })
              .catch(error => {
                self.showMessageSnackbar(
                  "Oops! Something went wrong. kindly contact your administrator"
                );
              });
          }
        };
      }
    },
    uploadBanner: function(
      image,
      mainIndex,
      currentIndex,
      name,
      getsize,
      prependPath
    ) {
      let size = getsize.split("*");
      var self = this;
      var _URL = window.URL || window.webkitURL;
      var file, img;
      if ((file = image.files[0])) {
        img = new Image();
        img.src = _URL.createObjectURL(file);
        img.onload = async function() {
          if (
            img.height != parseInt(size[1]) ||
            img.width != parseInt(size[0])
          ) {
            // if (false) {
            self.showMessageSnackbar("Please upload a " + getsize + " image");
            if (name == "MainBanner") {
              self.$refs.fileinputMainBanner.map((f, FIndex) => {
                if (FIndex == currentIndex) {
                  f.reset();
                }
              });
            } else if (name == "MainMobileBanner") {
              self.$refs.fileinputMainMobileBanner.map((f, FIndex) => {
                if (FIndex == currentIndex) {
                  f.reset();
                }
              });
            } else if (name == "MainLargeBanner") {
              self.$refs.fileinputMainLargeBanner.map((f, FIndex) => {
                if (FIndex == currentIndex) {
                  f.reset();
                }
              });
            } else if (name == "AppHeaderBanner") {
              self.$refs.fileinputAppHeaderBanner.map((f, FIndex) => {
                if (FIndex == currentIndex) {
                  f.reset();
                }
              });
            } else if (name == "AppImage") {
              self.$refs.fileinputAppImage.map((f, FIndex) => {
                if (FIndex == currentIndex) {
                  f.reset();
                }
              });
            }
          } else {
            var formdata = new FormData();
            formdata.append(prependPath, image.files[0]);
            formdata.append("rename", self.movieContainer[mainIndex].Title);
            UploadService.uploadFiles(formdata)
              .then(response => {
                const { data } = response;
                data.Message ? self.showMessageSnackbar(data.Message) : "";
                if (data.Status) {
                  self.movieContainer[mainIndex].MoviesFormatList[currentIndex][
                    name
                  ] = data.Path;
                  self.BaseURL = data.BaseURL;
                }
              })
              .catch(error => {
                self.showMessageSnackbar(
                  "Oops! Something went wrong. kindly contact your administrator"
                );
              });
          }
        };
      }
    },
    onChangeLanguage: function(index) {
      // movieContainer[index].Language
      // console.log(
      //   " this.movieContainer[index].Language :",
      //   this.movieContainer[index].Language.match(/[,]/g).length
      // );
      console.log(
        "this.movieContainer[index].Language :",
        this.movieContainer[index].Language
      );
      this.movieContainer[index]["moviesLanguageList"] = [];
      if (
        this.movieContainer[index].Language.match(/[,]/g) &&
        this.movieContainer[index].Language.match(/[:]/g) &&
        this.movieContainer[index].Language.match(/[,]/g).length >
          this.movieContainer[index].Language.match(/[:]/g).length
      ) {
        // if(/[,]/.test(this.movieContainer[index].Language)){
        this.movieContainer[index]["moviesLanguageList"] = [
          "Select Language",
          ...this.movieContainer[index].Language.split(",")
        ];
      } else if (
        this.movieContainer[index].Language.match(/[,]/g) &&
        this.movieContainer[index].Language.match(/[:]/g) &&
        this.movieContainer[index].Language.match(/[,]/g).length <
          this.movieContainer[index].Language.match(/[:]/g).length
      ) {
        this.movieContainer[index]["moviesLanguageList"] = [
          "Select Language",
          ...this.movieContainer[index].Language.split(":")
        ];
      } else if (this.movieContainer[index].Language.match(/[,]/g)) {
        console.log(
          "this.movieContainer[index].Language :",
          this.movieContainer[index].Language
        );

        this.movieContainer[index]["moviesLanguageList"] = [
          "Select Language",
          ...this.movieContainer[index].Language.split(",")
        ];
      } else if (this.movieContainer[index].Language.match(/[:]/g)) {
        this.movieContainer[index]["moviesLanguageList"] = [
          "Select Language",
          ...this.movieContainer[index].Language.split(":")
        ];
      } else {
        this.movieContainer[index]["moviesLanguageList"] = [
          "Select Language",
          ...this.movieContainer[index].Language.split(":")
        ];
      }
      this.movieContainer[index]["moviesLanguageList"] = this.movieContainer[
        index
      ]["moviesLanguageList"].map(function(y) {
        return y.trim();
      });
      console.log(
        "movieContainer[index] :",
        index,
        this.movieContainer[index]["moviesLanguageList"]
      );
      this.$forceUpdate();
    },
    __onChangeLanguage: function(index) {
      // movieContainer[index].Language
      // console.log(
      //   " this.movieContainer[index].Language.match(/[,]/g).length :",
      //   this.movieContainer[index].Language.match(/[,]/g).length
      // );
      // console.log(
      //   "this.movieContainer[index].Language :",
      //   this.movieContainer[index].Language
      // );
      if (
        this.movieContainer[index].Language.match(/[,]/g) &&
        this.movieContainer[index].Language.match(/[:]/g) &&
        this.movieContainer[index].Language.match(/[,]/g).length >
          this.movieContainer[index].Language.match(/[:]/g).length
      ) {
        // if(/[,]/.test(this.movieContainer[index].Language)){
        this.moviesLanguageList = [
          "Select Language",
          ...this.movieContainer[index].Language.split(",")
        ];
      } else if (
        this.movieContainer[index].Language.match(/[,]/g) &&
        this.movieContainer[index].Language.match(/[:]/g) &&
        this.movieContainer[index].Language.match(/[,]/g).length <
          this.movieContainer[index].Language.match(/[:]/g).length
      ) {
        this.moviesLanguageList = [
          "Select Language",
          ...this.movieContainer[index].Language.split(":")
        ];
      } else if (this.movieContainer[index].Language.match(/[,]/g)) {
        // console.log(
        //   "this.movieContainer[index].Language :",
        //   this.movieContainer[index].Language
        // );

        this.moviesLanguageList = [
          "Select Language",
          ...this.movieContainer[index].Language.split(",")
        ];
      } else if (this.movieContainer[index].Language.match(/[:]/g)) {
        this.moviesLanguageList = [
          "Select Language",
          ...this.movieContainer[index].Language.split(":")
        ];
      } else {
        // console.log('here :', here);
        this.moviesLanguageList = [
          "Select Language",
          ...this.movieContainer[index].Language.split(":")
        ];
      }
    },
    addFormat: function(mainIndex, currentIndex) {
      let array = [
        ...this.movieContainer[mainIndex].MoviesFormatList,
        {
          value: "",
          language: "Select Language",
          IsDisabled: false,
          youtube: "",
          ID: 0,
          MainBanner: "",
          MainMobileBanner: "",
          MainLargeBanner: "",
          AppHeaderBanner: "",
          AppImage: ""
        }
      ];
      this.movieContainer[mainIndex].MoviesFormatList = array;
    },
    removeFormat: function(mainIndex, currentIndex) {
      this.movieContainer[mainIndex].MoviesFormatList.splice(currentIndex, 1);
    },
    validation: function() {
      return true;
    },
    validationTemp: function() {
      let array = [
        "Title",
        "Genre",
        "Director",
        "Actors",
        "Plot",
        "Language",
        "imdbRating",

        // Testing
        "ActorDetail",
        "DirectorDetail",
        "MoviesFormatList",
        "MovieCountryData"
      ];

      // console.log("this.movieContainer.length :", this.movieContainer.length);

      for (let i = 0; i < this.movieContainer.length; i++) {
        const element = this.movieContainer[i];
        console.log("i :", i);
        for (let j = 0; j < array.length; j++) {
          let value = false;
          const key = array[j];
          if (key === "DirectorDetail") {
            if (
              !this.validateDirector(
                element["DirectorDetail"],
                element["DirectorDetail"].length
              )
            ) {
              this.showMessageSnackbar(
                `Movie ${key} list in ${element.ContentLanguageName} section fields cannot be empty.`
              );
              return false;
            }
          } else if (key === "ActorDetail") {
            if (
              !this.validateActor(
                element["ActorDetail"],
                element["ActorDetail"].length
              )
            ) {
              this.showMessageSnackbar(
                `Movie ${key} list in ${element.ContentLanguageName} section fields cannot be empty.`
              );
              return false;
            }
          } else if (key === "MoviesFormatList") {
            if (
              !this.validateMoviesFormat(
                element["MoviesFormatList"],
                element["MoviesFormatList"].length
              )
            ) {
              this.showMessageSnackbar(
                `Movie ${key} list in ${element.ContentLanguageName} section fields cannot be empty.`
              );
              return false;
            }
          } else if (key === "MovieCountryData") {
            if (!this.validateMovieCountryData()) {
              this.showMessageSnackbar(
                `Movie ${key} list in ${element.ContentLanguageName} section fields cannot be empty.`
              );
              return false;
            }
          } else if (element[key] == undefined || element[key] == "") {
            this.showMessageSnackbar(
              `Movie ${key} in ${element.ContentLanguageName} section cannot be empty.`
            );
            return false;
          }

          if (i + 1 == this.movieContainer.length && j + 1 == array.length) {
            return true;
          }
        }
      }
    },
    validateMovieCountryData: function() {
      // let array =
      return (
        this.MovieCountryData.filter(x => {
          return (
            x.Rated &&
            x.Released &&
            x.Runtime &&
            x.CountryID &&
            x.DistributorName &&
            x.CountryName &&
            //
            x.Rated !== undefined &&
            x.Released !== undefined &&
            x.Runtime !== undefined &&
            x.CountryID !== undefined &&
            x.DistributorName !== undefined &&
            x.CountryName !== undefined &&
            //
            x.Rated !== "" &&
            x.Released !== "" &&
            x.Runtime !== "" &&
            x.CountryID !== "" &&
            x.DistributorName !== "" &&
            x.CountryName !== "" &&
            //
            x.Rated.length > 0 &&
            x.Released.length > 0 &&
            x.Runtime.length > 0 &&
            // x.CountryID.length > 0 &&
            x.DistributorName.length > 0 &&
            x.CountryName.length > 0
          );
        }).length == this.MovieCountryData.length
      );
      // array.length == this.MovieCountryData.length;
    },
    validateMoviesFormat: function(array, arrayLength) {
      return (
        array.filter(x => {
          return (
            x.value !== undefined &&
            x.language !== undefined &&
            x.youtube !== undefined &&
            x.MainBanner !== undefined &&
            x.MainMobileBanner !== undefined &&
            x.MainLargeBanner !== undefined &&
            x.AppHeaderBanner !== undefined &&
            //
            x.value !== "" &&
            x.language !== "" &&
            x.language !== "Select Language" &&
            x.youtube !== "" &&
            x.MainBanner !== "" &&
            x.MainMobileBanner !== "" &&
            x.MainLargeBanner !== "" &&
            x.AppHeaderBanner !== "" &&
            //
            x.value.length > 0 &&
            x.language.length > 0 &&
            x.youtube.length > 0 &&
            x.MainMobileBanner.length > 0 &&
            x.MainLargeBanner.length > 0 &&
            x.MainBanner.length > 0 &&
            x.AppHeaderBanner.length > 0
          );
        }).length == arrayLength
      );
    },
    validateActor: function(array, arrayLength) {
      return (
        array.filter(x => {
          return (
            x.Image !== undefined &&
            x.Name !== "" &&
            x.Image.length > 0 &&
            x.Name.length > 0
          );
        }).length == arrayLength
      );
    },
    validateDirector: function(array, arrayLength) {
      return (
        array.filter(x => {
          return (
            x.Image !== undefined &&
            x.Name !== "" &&
            x.Image.length > 0 &&
            x.Name.length > 0
          );
        }).length == arrayLength
      );
    },
    submit: function() {
      let payload = {
        title: "Confirmation",
        message: "Please re-check the data before submit"
      };
      // if (this.validation()) {
      if (this.validationTemp()) {
        this.showSubmitConfirmation(payload);
      }
      // this.showSubmitConfirmation(payload);
    },
    submitFinalData: async function() {
      this.closeModal("ModalConfirmationOnSubmit");
      // console.log("this.movieContainer :", this.movieContainer);

      let obj = {
        MovieCountryData: this.MovieCountryData,
        MovieContainer: this.movieContainer
      };
      if (this.MovieID) {
        obj["Flag"] = 2;
        obj["MovieID"] = this.MovieID;
      } else {
        obj["Flag"] = 1;
      }
      console.log("this.movieContainer :", JSON.stringify(obj));

      MovieService.addMovie(obj)
        .then(response => {
          const { data } = response;
          data.Message ? this.showMessageSnackbar(data.Message) : "";
          if (data.Status) {
            this.resetForm();
            this.$router.push({
              path: "/movies/all-movies"
            });
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  },
  destroyed() {
    this.resetForm();
    this.movieContainer = [];
    this.contentLanguageList = [];
  }
};
</script>

<style scoped>
button.d-none.d-lg-block.CustomAside.navbar-toggler {
  background: linear-gradient(
    to right,
    rgb(44, 2, 73) 15%,
    rgb(224, 0, 124)
  ) !important;
}
</style>

